@import './stylesheets/mediaquery.css';
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,900;1,900&family=Space+Mono:ital,wght@0,400;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@1,800&display=swap');

html {
  scroll-behavior: smooth;
  padding: 0;
  background-color: rgb(9, 0, 17);
  background-image: url('./images/snekky.png');
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

body {
  font-family: 'Space Mono', monospace;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 1600px;
  width: 100%;
  z-index: 0;
  border: 4px solid black;
}

a {
  text-decoration: none;
  color: #000000;
}
.loading{
  font-family: 'Space Mono', monospace;
  font-size: 4rem;
  line-height: 5rem;
  background-color: #fff;
}
.row {
  display: flex;
  flex-direction: row;
}

.end {
  align-items: flex-end;
}

.column {
  display: flex;
  flex-direction: column;
  color: #666666;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.closed {
  width: 0;
  display: none;
}
.open {
  display: flex;
}

.flex-item-right {
  max-height: 100%;
  width: 45%;
  max-width: 45%;
  flex-direction: column;
  overflow: hidden;
  border-left: 4px solid rgb(0, 0, 0);
  background-color: #fef7ee;
}

.flex-item-left {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.home-container {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  min-width: 100%;
  min-height: 98vh;
  /* background-color: #fff; */
  background-color: #ffffffea;
  color: #666666;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.wrap {
  flex-wrap: wrap;
}
.flex-center-center {
  justify-content: center;
  align-items: center;
}
.sub-category {
  width: 100%;
}
div.mini-slide {
  padding: 0;
  margin: 0.2rem;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
}
.mini-slide div.flex-col {
  display: flex;
  color: #666666;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 11vw;
  min-width: 250px;
  max-width: 14vw;
  min-height: 250px;
  height: 11vw;
  max-height: 14vw;
  border: 0.5px solid black;
  text-align: left;
  outline: none;
  margin: 2rem 0.5rem;
  padding: 5px;
  box-shadow: -4px 4px 0px 0px rgba(51, 51, 51, 0.4);
}
.mini-slider-img {
  width: 100%;
  margin: 0;
  border: 1px solid black;
  align-self: center;
}
.mini-slide a {
  width: fit-content;
  color: #666666;
}
.mini-slide-text {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 1rem;
  color: #666666;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: left;
}
.more-link-container {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  min-width: 100%;
  color: #666666;
}
.more-link-container a {
  color: #666666;
  text-decoration: none;
}
.more-link-container a:hover {
  color: #000000;
}
.project-link-container {
  display: flex;
  justify-content: space-around;
  width: 50%;
  align-items: flex-end;
}
.mini-title {
  font-family: 'Libre Franklin', sans-serif;
  text-align: justify;
  font-size: 1rem;
  text-align: left;
  margin: 0;
  padding: 0;
}

.mini-project-link-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.2rem;
  font-weight: 600;
}
#links {
  font-family: 'Space Mono', monospace;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;
  margin-bottom: 2vh;
  margin-top: 1vh;
  margin-right: 1vw;
  text-align: right;
  align-self: baseline;
  align-self: flex-end;
}

#detail-header {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: #000;
}
.margin-bottom {
  margin-bottom: 2rem;
}
#summary-header {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 1.2rem;
  padding: 0 0;
  /* border: 1px dotted pink; */
}

.summary-text {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 400;
  margin-top: 3vh;
  font-size: 1rem;
  text-align: left;
  line-height: 1.5rem;
  color: #000;
}

.skills {
  border: 2px solid black;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #4e637b;
  align-content: stretch;
  justify-content: space-between;
  margin: 0 auto;
  color: #d8e3e8;
}

.skills li {
  padding: 2px;
  line-height: 1.5rem;
}

.skills .section-title a {
  color: #d8e3e8;
}

.skills #links a {
  color: #d8e3e8;
}

.home-list {
  font-size: 1.3rem;
  font-family: 'Libre Franklin', sans-serif;
  padding: 1rem;
  align-self: center;
}

.tools {
  grid-area: tools;
  border: 2px solid black;
  box-sizing: border-box;
  background-color: #999999;
  text-align: left;
  align-content: stretch;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.tools li {
  padding: 2px;
  line-height: 1.5rem;
}

.section-title {
  font-family: 'Space Mono', monospace;
  font-size: 2rem;
  font-weight: 400;
  min-width: 100%;
  text-transform: uppercase;
  padding-top: 0.3rem;
  margin-left: 0.2rem;
  text-align: left;
  white-space: nowrap;
}

.white {
  color: white;
}

/* media queries */

/* -----------------------------------Header and Top Nav------------------------------------------------------- */

header {
  position: sticky;
  top: 0;
  max-width: 1600px;
  min-width: 295px;
  width: 100%;
  left: 0;
  right: 0;
  height: 5rem;
  box-sizing: border-box;
  z-index: 500;
  font-family: 'Space Mono', monospace;
  font-weight: 400;
  background-color: white;
  border-bottom: 4px solid black;
}

/* #d9d1c9 */
div.name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  width: 40%;
  border-right: 4px solid black;
  overflow: hidden;
  background-color: #91b9c8;
  text-decoration: none;
  font-size: 1.6rem;
  font-family: 'monotalic', sans-serif;
  font-weight: 300;
  text-overflow: ellipsis;
}

img.logo {
  max-width: 80%;
}
/* menu */

button.hamburg-icon {
  border: none;
}

div.hamburg-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  border-left: 4px solid black;
  height: 100%;
  width: 5rem;
  background: transparent;
  z-index: 1000;
  transition: transform 0.6s ease;
  align-self: flex-end;
}

.closeIcon {
  display: flex;
}

/* --------------------------------- About Me -------------------------- */

.flex-item {
  width: 50%;
  display: flex;
  justify-content: center;
  min-width: 425px;
  max-width: 800px;
  background-color: #fff;
  min-height: 300px;
  max-height: 600px;
  /* border: 1px dotted purple; */
}

.para {
  width: 80%;
  /* height:400px; */
  background-color: #fff;
  /* border: 1px dotted blue; */
  align-self: center;
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.creative #para {
  /* margin-top:0rem; */
  align-self: flex-start;
  margin-top: 2rem;
}

.flex-item .creative {
  min-height: 200px;
  max-height: 600px;
  /* border: 1px dotted red; */
}

.photo1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #d8e3e8;
}
.photo1 img {
  max-width: 80%;
}
.photo1 p {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 1rem;
  text-align: left;
  margin: 1rem;
}
.photo-small {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #d8e3e8;
  align-content: center;
  justify-content: space-evenly;
}
.photo-small img {
  width: 45%;
  min-height: 20%;
}
.photo-small p {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 1rem;
  text-align: left;
  margin: 1rem;
}
/* --------------------------------professional------------------- */

.prof {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: stretch;
  max-width: 1600px;
}

.prof #detail-header {
  margin-top: 2vh;
  font-size: 1.5rem;
  background-color: transparent;
}

.head-shot {
  /* box-sizing: border-box; */
  max-height: 80%;
  min-width: 100%;
  /* min-width: 280px; */
  /* max-width: 400px; */
  /* min-height: 200px;
  max-height:500px; */
}

#graph1 {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background-color: #d8e3e8;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.graphs {
  width: 90%;
  max-width: 505px;
  display: block;
}

#photo1-plum {
  display: flex;
  justify-content: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #d8e3e8;
}

.personal {
  width: 80%;
  max-width: 397px;
}

.contact {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

/* ---------------------------- Contact Me ---------------------- */

.contact {
  grid-area: cont;
  border-top: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 0px;
  box-sizing: border-box;
  background-color: #d8e3e8;
  display: flex;
  width: 100%;
  color: #666666;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  /* box-shadow: inset 0 0 10px #565656; */
  outline: none;
}

.connect-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  font-family: 'Libre Franklin', sans-serif;
  text-align: left;
  font-size: 1rem;
  margin-top: 0.7rem;
  outline: none;
  line-height: 1.5rem;
}

.connect-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 3rem;
  height: 60%;
  outline: none;
  line-height: 1.5rem;
}

.connect-container p {
  margin-bottom: 10px;
}

.thank-you {
  color: #cc9900;
}

.input-box {
  box-sizing: border-box;
  margin-top: 2.5rem;
  width: 40%;
  height: 20px;
  display: inline;
  color: #666666;
  background-color: transparent;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 1rem;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #666666;
  padding-right: 1rem;
}

.input-box:hover {
  border-bottom: 2px solid #000;
}

.input-box:focus {
  border-bottom: 2px solid #000;
  color: #000;
  outline: none;
}

.input-box::placeholder {
  color: #666666;
}

.input-box:hover::placeholder {
  color: black;
}

.input-box:focus::placeholder {
  color: black;
}

.margin-left {
  margin-left: 2rem;
}

.subject {
  height: 20px;
  width: 99%;
  font-size: 1rem;
  font-family: 'Libre Franklin', sans-serif;
  color: #666666;
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #666666;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0.5rem;
}

.subject:hover {
  border-bottom: 2px solid #000;
}

.subject:focus {
  border-bottom: 2px solid #000;
  color: #000;
  outline: none;
}

.subject::placeholder {
  color: #666666;
}

.subject:hover::placeholder {
  color: #000;
}

.subject:focus::placeholder {
  color: #000;
}

.comment-area {
  width: 99%;
  height: 100px;
  padding: 0.5rem;
  font-size: 1rem;
  color: #666666;
  box-sizing: border-box;
  background-color: transparent;
  resize: none;
  border: 2px solid #666666;
  margin-bottom: 20px;
  font-family: 'Libre Franklin', sans-serif;
}

.comment-area::placeholder {
  color: #666666;
}

.comment-area:focus {
  color: #000;
  border: 2px solid #000;
  outline: none;
}

.comment-area:focus::placeholder {
  color: #000;
}

.comment-area:hover::placeholder {
  color: #000;
}

.comment-area:hover {
  border: 2px solid #000;
}

.connect-submit {
  width: 99%;
  text-align: center;
  background-color: transparent;
  border: 2px solid #666666;
  padding: 0.2rem;
  font-family: 'Libre Franklin', sans-serif;
  color: #666666;
  font-size: 1.2rem;
  margin: 0;
}

.connect-submit:hover {
  cursor: pointer;
  color: #000;
  border: 2px solid #000;
}

.connect-submit:focus {
  outline: none;
  color: #000;
  border: 2px solid #000;
}

.make-white a {
  color: #ffffff;
}

div.make-light-blue {
  background-color: #d8e3e8;
}

.con-h {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #d8e3e8;
  font-family: 'Space Mono', monospace;
  font-size: 1rem;
  text-align: left;
  overflow: hidden;
  padding: 2rem;
  margin: 5rem;
  border: 2px solid #666666;
}

.con-h p {
  margin-top: 0.3rem;
  width: 100%;
  color: #666666;
}

.con-h #detail-header {
  color: #666666;
  border-bottom: 2px solid #000;
  width: 100%;
}

.con-h h5 {
  color: #666666;
  margin-top: 1rem;
}
.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.email {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid black;
}

.resume a:hover {
  color: #000;
  background-color: green;
}

/* ---------------------------- Footer----------------------------- */

.footer {
  height: 5%;
  max-width: 2300px;
  display: flex;
  justify-content: left;
  border-top: 4px solid #000;
  align-items: flex-end;
  background-color: rgb(255, 255, 255);
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 501;
}

.footer a {
  margin: auto;
  box-sizing: border-box;
  /* background-color: white; */
  font-family: 'Libre Franklin', sans-serif;
  font-size: 1.5rem;
  text-align: center;
  color: #000;
}

#mailto a {
  content: '';
  display: block;
  color: #000;
}

#mailto:hover {
  color: #ff9900;
}

#mailto div {
  width: 2rem;
  height: 2rem;
}

#linkedin-link {
  color: #000;
  margin-left: 1.6rem;
}

#linkedin-link:hover {
  color: #ff9900;
}

#linkedin-link div {
  width: 2rem;
  height: 2rem;
}

#github-link {
  color: #000;
  margin-left: 1.6rem;
}

#github-link:hover {
  color: #ff9900;
}

#github-link div {
  width: 2rem;
  height: 2rem;
}

.footer a:hover {
  color: #ff9900;
}

.footer h1 {
  font-size: 1rem;
}

/* ----------------------------------------------Home-------------------------------- */

/* for project list bar */

.projects {
  width: 60%;
  height: 90%;
  background-color: #3f7beb;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.projects-header {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 400;
  /* color: #565656; */
  font-size: 0.9rem;
  align-self: center;
  /* position: absolute; */
  margin-bottom: 0.3rem;
  letter-spacing: 0.3em;
}

.post-wrapper {
  width: 100%;
  display: flex;
}

.accordion__section {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.card-header {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
  background-color: none;
  width: 100%;
  line-height: 2.5rem;
  padding: 0 !important;
}

.card {
  display: flex;
  background-color: none;
  min-width: 100% !important;
  max-width: 400px;
  padding: 0;
  border: 0;
}

.card-body {
  min-width: 100%;
  padding: 0 !important;
}

.card-body a:hover {
  color: black;
}

/* Style the buttons that are used to open and close the accordion panel */

.nowrap {
  white-space: nowrap;
}

/* Hi component */

.portrait {
  background-color: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  /* background-clip: border-box; */
  border: 2px solid black;
  box-sizing: border-box;
  /* box-shadow: inset 0 0 10px #898989; */
}

.home-list {
  background-color: rgba(0, 0, 0, 0);
  min-height: 400px;
  width: 100%;
  /* background-clip: border-box; */
  box-sizing: border-box;
  /* box-shadow: inset 0 0 10px #898989; */
}

.circle-image {
  background-image: url('./images/circleTay.jpg');
  border-radius: 50%;
  width: 25vw;
  height: 25vw;
  max-width: 400px;
  max-height: 400px;
  margin-top: 3rem;
  margin-bottom: 1rem;
  background-size: 200%;
  background-color: #4d4c4c00;
  background-position: center 10%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-size: cover;
}

.hi {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  font-family: 'Libre Franklin', sans-serif;
  font-size: 1.5rem;
  padding: 5rem;
  margin: 5rem;
  border: 2px solid black;
}

.hi h6 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: 2rem;
  text-align: center;
}

.container p {
  color: #000000;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: 'Libre Franklin', sans-serif;
  margin-top: 1rem;
}

.more-link {
  font-size: 0.9rem;
  font-weight: 900;
  text-align: left;
  text-transform: uppercase;
  align-self: center;
}

.hi p {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.feature-section {
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
/* ------------------------------------------ Projects --------------------------- */

.single-proj-row {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
}

.end {
  align-items: flex-end;
}

.single-proj-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.prevImg {
  align-self: center;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background-position: center;
  background-repeat: no-repeat;
}

.prevImg img {
  width: 100%;
  /* height: auto; */
}

.project-title {
  width: 40%;
  white-space: nowrap;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
}

.project-details {
  width: 100%;
  min-height: 100%;
  text-align: center;
  margin: 0 auto;
  overflow: visible;
}

.project-image {
  width: 90%;
  margin-bottom: 1rem;
  border: 1px solid black;
}

div.project-description {
  max-width: 90%;
  border: 2px solid red;
  font-size: 1rem;
  font-family: 'Space Mono', monospace;
}

.detail {
  display: flex;
  flex-flow: row wrap;
  outline: none;
  height: auto;
}

.projects-title {
  font-family: 'Space Mono', monospace;
  font-size: 1.2rem;
  font-weight: 400;
  min-width: 100%;
  text-transform: uppercase;
  padding-top: 0.3rem;
  margin-left: 0.2rem;
  text-align: left;
  white-space: nowrap;
}

.year {
  font-weight: 400;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: top;
}

.summary-text {
  text-align: left;
  font-family: 'Libre Franklin', sans-serif;
  padding-top: 0.4rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.page-header {
  order: 0;
  flex: 2 90%;
  height: 50px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 3rem;
  padding-bottom: 0.5rem;
  margin: 0 auto;
  border-bottom: 1px solid #000;
}
/* ---------------------------- 1duplicate-------------------------------------------------------------------------------------------------------------------------- */

.proj-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-around;
  width: 90%;
  max-width: 1600px;
  height: 100%;
}

.proj-card {
  display: flex;
  align-items: flex-start;
  width: 30%;
  min-width: 325px;
  max-width: 475px;
  height: auto;
  min-height: fit-content;
  border: 0.5px solid black;
  /* max-height: 15rem; */
  text-align: center;
  background-color: #fff;
  /*border: 1px solid rgb(0, 0, 0); #565656; */
  outline: none;
  margin: 15px 15px;
  padding: 5px;
  box-shadow: -4px 4px 0px 0px rgba(51, 51, 51, 0.4);
}

.proj-card:hover {
  box-shadow: -4px 4px 0px 0px rgb(51, 51, 51);
}

.project-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-around;
  width: 80%;
  height: 100%;
  overflow: auto;
}

.single-project {
  display: flex;
  width: 100%;
  border: 0.5px solid black;
  align-items: stretch;
  outline: none;
  margin: 0.5rem 0.5rem;
  padding: 5px;
  box-shadow: -4px 4px 0px 0px rgb(51 51 51 / 40%);
}

.single-project .column {
  width: 100%;
  color: #666666;
  padding: 0.5rem;
}

.single-project .row {
  justify-content: space-evenly;
  width: 100%;
}

.projects-container {
  padding: 0;
  margin: 0;
  min-width: 90%;
  min-height: 80%;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* border-bottom: 4px solid rgb(0, 0, 0); */
  /* background-color: white; */
}

.projects-header {
  width: 100%;
}

.breadcrumb-link {
  align-self: center;
  font-family: 'Space Mono', monospace;
  font-size: 1.2rem;
  font-weight: 400;
  min-width: 100%;
  text-transform: uppercase;
  padding-top: 0.3rem;
  margin-left: 0.2rem;
  text-align: left;
  white-space: nowrap;
}

.card:hover {
  box-shadow: -4px 4px 0px 0px rgb(51, 51, 51);
}

.proj-column {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
  height: auto;
  min-width: 50%;
  padding: 0.8rem;
}

.slide-info {
  text-align: left;
  margin: 0.3rem 0 0.3rem 0;
  width: 100%;
}

.slide-summary {
  font-family: 'Space Mono', monospace;
  font-weight: 400;
  font-size: 0.8rem;
  width: 100%;
  /* margin-left: 5px; */
  color: #565656;
}

.slide-text {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 1rem;
  color: #242424;
  margin-bottom: 0.5rem;
  margin-right: 5rem;
}
.link-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.link-box a {
  font-family: 'Sarabun', sans-serif;
  font-style: italic;
  font-weight: 700;
  color: #666;
}
.link-box a:hover {
  color: #000;
}
.icon-row {
  display: flex;
  padding: 0.5rem;
  align-items: stretch;
  width: fit-content;
  flex-direction: row;
  justify-content: space-around;
  color: #000;
}
.icon-row div {
  margin: 0.5rem;
}
.slide-header {
  display: flex;
  font-family: 'Libre Franklin', sans-serif;
  margin-top: 0.5rem;
  white-space: nowrap;
  justify-content: space-between;
  align-items: baseline;
}

.column span.slide-header {
  font-size: 2rem;
  color: #666666;
}

.port-header {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
  display: inline;
  font-size: 1rem;
  align-self: flex-start;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-bottom: 0;
}

/* Style the accordion chevron icon */

.project-accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */

.project__content {
  overflow: hidden;
  background-color: #e1d9e0;
  /* background-color: #a999ab9b; */
  border: none;
  text-align: left;
  transition: max-height 0.6s ease;
}

section.projects {
  display: flex;
  background-color: #ffffff;
  min-height: 90%;
  align-items: center;
}

/* .project__accordion:hover,
.activeProject {
  color: #00bbff;
} */
.bulleted-list li {
  font-family: 'Sarabun', sans-serif;
  font-style: italic;
  font-weight: 700;
  list-style-position: inside;
  list-style-type: square;
  color: rgb(56, 49, 49);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}

.bulleted-list {
  margin-right: 1rem;
  width: fit-content;
  padding-right: 1rem;
}
.rotate {
  transform: rotate(90deg);
}

.collapse {
  padding: 0;
}

.navBar {
  max-width: 100%;
}

.react-icons {
  vertical-align: middle;
}

.color1 {
  background-color: #f8fbdb;
}

.color2 {
  background-color: #fcf2e3;
}

.detail-summary {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1vh;
  display: inline-block;
  vertical-align: top;
}

.catNav-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 8rem;
  background-color: #e1d9e0;
  /* border-right: 2px solid black;
  border-left: 4px solid black;
  border-bottom: 2px solid black; */
}

.outer-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: 100%;
  border-right: 4px solid black;
  border-left: 4px solid black;
  border-bottom: 4px solid black;
  background-repeat: repeat;
  z-index: 3;
}

aside .sidebar {
  height: 100%;
  border-bottom: 2px solid black;
}

.sidebar .categories {
  background-color: white;
  border-bottom: 4px solid black;
  /* margin-top: 4.5rem; */
  /* border-bottom: 4px solid black */
}

.sidebar button.collapse-categories {
  height: 35px;
  border: 0px;
  color: transparent;
  background: transparent;
  font-size: 2rem;
}

.sidebar button.collapse-categories visible {
  color: #000;
}

.sidebar button.collapse-categories:hover {
  color: black;
  background-color: #ffe8c4;
}

.sub-category {
  margin-left: 0.2rem;
  width: 100%;
}

.sidebar button.collapse-categories div {
  border: none;
  line-height: 0;
  padding: 1px 3px;
  font-size: 2rem;
}

.sidebar .title {
  display: flex;
  width: 100%;
  text-align: left;
  flex-direction: row;
  background-color: rgb(254, 254, 254);
  /* border: 1px solid rgb(255, 255, 255); */
  outline: none;
  border: 2px solid #ffffff;
  padding-left: 1rem;
}

.sidebar button.category {
  border: none;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  background: #ffffff;
  color: #000000;
  font-family: 'Sarabun', sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 1.6rem;
  cursor: pointer;
  outline: none;
}

.sidebar a {
  padding-left: 0.3rem;
  display: flex;
  align-items: center;
  background: #ffffff;
  font-size: 2.4rem;
  min-width: 100%;
  line-height: 1.2;
  color: black;
  font-family: 'Sarabun', sans-serif;
  font-style: italic;
  font-weight: 700;
  border-left: 3px solid rgb(254, 254, 254);
  outline: none;
}

.sidebar .border-bottom {
  position: sticky;
  top: 0;
  bottom: 0;
}

.site-link {
  width: 100%;
  border-bottom: 2px solid black;
}

.sidebar button.category:hover {
  background: #fef7ee;
  outline: none;
}

.sidebar button.category:active {
  outline: none;
  background: #fef7ee;
  border-bottom: none;
}

.sidebar button.category:focus {
  background: #fef7ee;
  outline: none;
}

.stroke-fill {
  stroke: #00000000;
}

span.project__text {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 400;
  font-variant: normal;
  width: 100%;
  line-height: 1.3rem;
}

.sidebar div.file-div {
  display: flex;
  color: #000000;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  flex-direction: row;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1rem;
  width: 100%;
  /* margin-top: .2rem; */
}

.sidebar div.icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.2rem;
  text-align: right;
}

.sidebar div.file-div:hover,
.sidebar div.file-div:focus {
  color: #000000;
}

.sidebar project__text:focus,
.sidebar project__text:active {
  outline: none;
  color: black;
}

.sidebar nav {
  width: 100%;
  color: #000000;
}

.sidebar nav.collapsed {
  display: none;
}

.sidebar a:hover {
  background: #fef7ee;
  outline: none;
}

.sidebar a.active,
.sidebar a:focus {
  outline: none;
  border-left: 3px solid #ff8c00;
  background: #fef7ee;
}

.fourOfour {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #f8fbdb;
  color: #000000;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 400;
  font-size: 5rem;
  margin-top: 1vh;
  display: inline-block;
  vertical-align: center;
}
